import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import YouTube from 'react-youtube';

const ProductDemoVideo = () => {

  const DemoVideo = () => {
    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 1,
      },
    };
 
    return <YouTube videoId="fT1_a2Gew-k" opts={opts}  />;

  };

  return (
    <Layout>
      <Helmet>
        <title>Product Demo</title>
        <meta name="description" content={"Product Demo"} />
      </Helmet>
      <div>

        <h1 className="post-title">Product Demo MVP</h1>
        <div className="content-container">
          <DemoVideo />
      </div>
      </div>
    </Layout>
  );
};

export default ProductDemoVideo;
